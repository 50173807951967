<template>
    <div class="form__block">
        <iframe 
        src="https://forms.yandex.ru/u/6415b46e84227c1453957cfe/?iframe=1" 
        frameborder="0" 
        name="ya-form-6415b46e84227c1453957cfe" 
        width="650"></iframe>
    </div>
</template>

<script>
export default {
    name: 'Form', 
    // mounted: {
    //     fetch('https://yastatic.net/s3/frontend/forms/_/embed.js');
    // }
}
</script>

<style lang="scss">

</style>