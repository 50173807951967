<template>
    <div class="form__body">
        <YForm />
    </div>
</template>


<script>
import YForm from '@/components/YandexForm/YForm'
export default {
    name: 'Registration',
    components:{
        YForm,
    }
}
</script>


<style lang="scss">
    .form{
        &__body{
            transform: translateX(-40%);
            margin-top: 1rem;
            background: #fff;
            box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
            padding: 3rem 1rem 1rem;
        }

        &__block, &__body{
            height: fit-content;
        }
    }
</style>